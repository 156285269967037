<template>
  <th-page-wrapper>
    <suppliers-list-empty
      v-if="!suppliersStore.suppliersExists"
      :template-href="templateHref"
      @refresh="refresh"
    />
    <th-datatable
      v-show="suppliersStore.suppliersExists"
      ref="table"
      class="hotjar-ignore"
      do-route
      do-route-filters
      headers-filterable
      multiple-select
      resource="suppliers"
      route-base="/supplier-management/suppliers"
      show-search-filter
      transform-fetched-meta-allowed
      :export-options="{
        waitingContent: $t('common.interactions.download.waiting')
      }"
      :headers="headers"
      :show-operations="false"
      :operations="operations"
      :resource-limit="100"
      :resource-query="{ query: resourceQuery }"
      :meta-options="resourceQuery"
      :locale="locale"
      :headers-config="headersConfig"
      :loading-text="loadingText"
      :search-filters="filtersList"
      :buttons="computedButtons"
      :retry-options="retryOptions()"
      @row-click="handleRowClick"
      @headers-config="handleHeadersConfig"
      @selection-change="handleSelectionChange"
      @loading-error="handleLoadingError"
    >
      <template #actions class="actions">
        <actions
          v-permissions="{
            scopes: ['supplier_management:suppliers:delete']
          }"
          :selected-items="selectedItems"
          @delete-requested="handleDelete"
        />
      </template>
      <template #dropdown-items-start>
        <template-file-button :href="templateHref" />
        <suppliers-importer
          v-if="
            $checkPermissions({
              scopes: ['supplier_management:suppliers:create']
            })
          "
          @refresh="refresh"
        />
      </template>
    </th-datatable>
  </th-page-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import { useSuppliersStore } from '@/store/suppliers'
import th from '@tillhub/javascript-sdk'
import typeOf from 'just-typeof'
import get from 'just-safe-get'
import Actions from '@components/actions/delete'
import SuppliersListEmpty from './empty'
import SuppliersImporter from './components/suppliers-importer'
import TemplateFileButton from '@components/importer/template-file-button'
import { useMessagesStore } from '@/store/messages'
import { useExportsStore } from '@/store/exports'

const headersConfigPath = 'settings.headerFilters.suppliers'

export default {
  name: 'SuppliersAll',
  metaInfo() {
    return {
      title: this.$t('common.resource.supplier.plural')
    }
  },
  components: {
    Actions,
    SuppliersListEmpty,
    SuppliersImporter,
    TemplateFileButton
  },
  setup() {
    const suppliersStore = useSuppliersStore()
    return { suppliersStore }
  },
  data() {
    return {
      templateHref:
        'https://storage.googleapis.com/tillhub-dashboard/templates/vorlage_lieferantenliste.xlsx',
      loadingExport: false,
      selectedItems: [],
      permissions: {
        create: {
          scopes: ['supplier_management:suppliers:create']
        },
        edit: {
          scopes: ['supplier_management:suppliers:update']
        },
        delete: {
          scopes: ['supplier_management:suppliers:delete']
        }
      },
      filtersList: [
        {
          name: 'companyName',
          type: 'input',
          label: this.$t('common.forms.labels.company_name')
        },
        {
          name: 'number',
          type: 'input',
          label: this.$t('pages.suppliers.form.placeholder.supplier_number')
        },
        {
          name: 'taxNumber',
          type: 'input',
          label: this.$t('pages.suppliers.form.placeholder.tax_number')
        }
      ],
      width: 500,
      headers: [
        {
          field: 'companyName',
          label: this.$t('common.forms.labels.company_name'),
          fallback: '-',
          minWidth: 130,
          truncate: true
        },
        {
          field: 'email',
          label: this.$t('common.forms.labels.email'),
          fallback: '-',
          minWidth: 200,
          truncate: true
        },
        {
          field: 'firstName',
          label: this.$t('common.forms.labels.firstname'),
          fallback: '-',
          minWidth: 130,
          truncate: true
        },
        {
          field: 'lastName',
          label: this.$t('common.forms.labels.lastname'),
          fallback: '-',
          minWidth: 130,
          truncate: true
        },
        {
          field: 'number',
          label: this.$t('pages.suppliers.form.placeholder.supplier_number'),
          fallback: '-',
          minWidth: 140,
          truncate: true
        },
        {
          field: 'taxNumber',
          label: this.$t('pages.suppliers.form.placeholder.tax_number'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        }
      ],
      loadingText: ''
    }
  },
  computed: {
    ...mapGetters({
      locale: 'Config/getLocale',
      currentLocation: 'Config/getCurrentLocation',
      localConfiguration: 'Config/getLocalConfiguration'
    }),
    resourceQuery() {
      return {
        deleted: false,
        locations: this.currentLocation || undefined
      }
    },
    buttons() {
      return [
        {
          type: 'create',
          scopes: ['supplier_management:suppliers:create'],
          clickHandler: this.handleNewRequest
        },
        {
          type: 'custom_export',
          scopes: ['supplier_management:suppliers:export'],
          clickHandler: this.handleExport
        }
      ]
    },
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    },
    headersConfig() {
      return get(this.localConfiguration, headersConfigPath, {})
    },
    operations() {
      const permissions =
        typeOf(this.permissions) === 'object' ? this.permissions : {}
      return Object.entries(permissions).reduce(
        (ops, [action = '', { scopes = [] }]) => {
          if (!action || !scopes || !scopes.length) return ops

          const permissionVal = this.$checkPermissions({ scopes })

          if (typeOf(permissionVal === 'boolean')) {
            ops[action] = permissionVal
          }

          return ops
        },
        {}
      )
    }
  },
  mounted() {
    this.suppliersStore.checkSuppliersCount()
    this.$emitter.on('refresh-requested', () => {
      this.$refs.table.refresh()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },
  methods: {
    handleSelectionChange(val) {
      this.selectedItems = val
    },
    refresh() {
      this.$refs.table.refresh()
      this.suppliersStore.checkSuppliersCount()
    },
    handleHeadersConfig(config) {
      this.$store.dispatch('Config/setLocalConfigurationValue', {
        path: headersConfigPath,
        value: config || {}
      })
    },
    async handleDelete(payload) {
      const confirm = await this.$askToDeleteMany(payload, null, 'companyName')
      if (confirm) this.deleteSupplier(payload)
    },
    async deleteSupplier(payload) {
      if (this.selectedItems.length > 1) {
        this.handleBulkDelete(this.selectedItems)
        return
      }
      const options = { resource: this.$t('common.resource.supplier.singular') }

      try {
        const inst = th.suppliers()
        await inst.delete(payload[0].id)
        this.$message({
          type: 'success',
          message: this.$t('common.success.action.delete.single', options)
        })
        this.refresh()
      } catch (err) {
        this.$logException(err, {
          message: this.$t('common.error.action.delete.single', options)
        })
      }
    },
    handleBulkDelete(items) {
      const inst = th.suppliers()

      const operations = items.map((item) => {
        return () => inst.delete(item.id)
      })

      const label = this.$t('common.success.action.delete.multiple', {
        resources: this.$t('common.resource.supplier.plural')
      })

      const currentRoute = this.$route.fullPath

      const fulfillment = () => {
        //check supplier count
        this.suppliersStore.checkSuppliersCount()

        if (this.$route.fullPath === currentRoute) {
          this.refresh()
        }
      }

      useMessagesStore().startLocalOperation({
        operations,
        label,
        fulfillment
      })
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('common.resource.supplier.plural')
        })
      })
    },
    retryOptions() {
      const loadingMsg = this.$t('common.loading.long')
      return {
        retries: 4,
        onFailedAttempt: (err) => {
          if (err.attemptNumber === 2) {
            this.loadingText = loadingMsg
          }
        }
      }
    },
    handleRowClick() {
      this.$ampli.eventWithBaseProps('supplierRowClick')
    },
    handleNewRequest() {
      this.$ampli.eventWithBaseProps('supplierNewButtonClick')
      this.$router.push({ name: 'supplier-new' })
    },
    async handleExport({ resourceOptions }) {
      this.$ampli.eventWithBaseProps('supplierExportButtonClick')
      const query = {
        ...resourceOptions,
        query: {
          ...resourceOptions.query,
          format: 'csv',
          filename_prefix: this.$t('pages.suppliers.title'),
          timezone: this.timeZone || 'Europe/Berlin'
        }
      }
      try {
        const { data } = await th.suppliers().export(query)
        const exportResult = data[0]

        if (!exportResult || !exportResult.url) {
          throw new Error(`Export API returned incorrect result`)
        }

        useExportsStore().setFinishedExport({
          exportResult,
          payload: {
            originKey: 'common.resource.supplier.plural',
            date: new Date(),
            action: {
              entity: 'suppliers',
              query
            }
          }
        })
      } catch (err) {
        this.$logException(err, {
          message: this.$t('notifications.exports.error.text', {
            entity: this.$t('common.resource.supplier.plural')
          })
        })
      }
    }
  }
}
</script>

<style scoped>
.actions {
  display: flex;
  justify-content: flex-start;
  justify-items: flex-start;
  align-content: center;
  align-items: center;
}

.actions > * {
  margin-right: 10px;
}

.popover {
  margin-right: 10px;
}

.tools > div {
  display: flex;
  align-items: flex-end;
}
</style>
