<template>
  <div
    class="w-full h-full flex flex-col bg-th-navigation"
    data-testid="suppliers-empty"
  >
    <div class="flex justify-between items-center py-6 pr-6 pl-8">
      <div
        class="h-full flex flex-col flex-end font-bold leading-7 text-lg max-w-lg"
        :class="{ italic: !isWhiteLabel }"
      >
        <div v-text="$t('pages.suppliers.empty.text.title')" />
        <div v-text="$t('pages.suppliers.empty.text.sub')" />
      </div>
      <el-dropdown
        split-button
        type="primary"
        icon="Plus"
        @click="computedButtons[0].clickHandler"
      >
        <span>{{ computedButtons[0].label }}</span>
        <template #dropdown>
          <el-dropdown-menu>
            <template-file-button :href="templateHref" />
            <supplier-importer
              v-if="$checkPermissions(['supplier_management:suppliers:create'])"
              @refresh="$emit('refresh')"
            />
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <div class="h-full flex min-h-0 min-w-0 items-center justify-center">
      <img
        :src="require('@/assets/illustrations/customers-list-empty.svg')"
        alt="suppliers-list-empty"
        style="max-width: 80%; max-height: 71vh"
        loading="lazy"
      />
    </div>
    <div class="th-shadow-top h-20" />
  </div>
</template>

<script>
import SupplierImporter from './components/suppliers-importer'
import TemplateFileButton from '@components/importer/template-file-button'
import { isUnifiedCommerce } from '@/constants'

export default {
  components: { SupplierImporter, TemplateFileButton },
  props: {
    templateHref: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      buttons: [
        {
          scopes: ['supplier_management:suppliers:create'],
          svgicon: 'th-icon-plus',
          label: this.$t('common.components.th_datatable.addNew'),
          clickHandler: this.handleNew
        }
      ]
    }
  },
  computed: {
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    },
    isWhiteLabel() {
      return isUnifiedCommerce()
    }
  },
  methods: {
    handleNew() {
      this.$router.push({ name: 'supplier-new' })
    }
  }
}
</script>

<style scoped>
.th-shadow-top {
  box-shadow: 0px -4px 4px rgba(167, 171, 177, 0.15);
}
</style>
