<template>
  <el-dropdown-item @click.stop="handleImport">
    <svgicon
      :src="require('@/assets/icons/th-icon-upload.svg')"
      :style="{ height: '20px', width: '20px' }"
      class="mr-2 fill-current"
    />
    <span>{{ $t('common.interactions.buttons.import') }}</span>
  </el-dropdown-item>
</template>

<script>
import { useStore } from 'vuex'
import { storeToRefs } from 'pinia'
import { useSuppliersStore } from '@/store/suppliers'
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import th from '@tillhub/javascript-sdk'
import FlatfileImporter from '@flatfile/adapter'
import get from 'just-safe-get'
import { isEmptyString } from '@/utils/strings'
import { i18nOverrides, splitArrayIntoChunks } from '@/utils/importer'
import { countries } from '@/utils/address'
import { addressTypes, phoneTypes, createAccountOptions } from '../helpers'
import { isValidIban, isValidSwift } from '@/components/bank-details/helpers'

const FLATFILE_LICENSE_KEY = process.env.VUE_APP_FLATFILE_LICENSE_KEY
const CHUNK_NUMBER = 200

export default {
  name: 'SupplierImporter',
  setup() {
    //store
    const store = useStore()
    const clientAccountConfiguration = computed(
      () => store.getters['Config/getClientAccountConfiguration']
    )

    const supplierStore = useSuppliersStore()
    const { suppliersCount } = storeToRefs(supplierStore)

    const userId = computed(() => store.state.Auth.user || '-')
    const orgName = computed(() => store.state.Auth.orgName || '-')

    //i18n
    const { t, tm } = useI18n()

    //data
    const resources = ref({})

    const fields = computed(() => [
      {
        key: 'companyName',
        label: t('common.forms.labels.company_name'),
        validators: [{ validate: 'required' }]
      },
      {
        key: 'description',
        label: t('common.titles.description'),
        validators: [
          {
            // Max length 1024
            validate: 'regex_matches',
            regex: `^.{0,1024}$`,
            error: t('common.forms.rules.min_max_length', {
              min: 0,
              max: 1024
            })
          }
        ]
      },
      {
        key: 'number',
        label: t('pages.suppliers.form.placeholder.supplier_number'),
        validators: supplierNumberValidators.value
      },
      {
        key: 'firstName',
        label: t('common.forms.labels.firstname')
      },
      {
        key: 'lastName',
        label: t('common.forms.labels.lastname')
      },
      {
        key: 'email',
        label: t('common.forms.labels.email'),
        validators: [
          {
            // The regex for email validation is in accordance to `RFC 2822 standard`
            // Source: https://www.w3resource.com/javascript/form/email-validation.php
            validate: 'regex_matches',
            regex:
              '^((?:[aA-zZ0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[aA-zZ0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[aA-zZ0-9](?:[aA-zZ0-9-]*[aA-zZ0-9])?\\.)+[aA-zZ0-9](?:[aA-zZ0-9-]*[aA-zZ0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[aA-zZ0-9-]*[aA-zZ0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\]))$',
            error: t('pages.suppliers.importer.validation.email.error')
          }
        ]
      },
      {
        key: 'phoneNumber',
        label: t('common.forms.labels.phonenumber')
      },
      {
        key: 'street',
        label: t('common.forms.labels.street')
      },
      {
        key: 'streetNumber',
        label: t('common.forms.labels.street_number')
      },
      {
        key: 'locality',
        label: t('common.forms.labels.city')
      },
      {
        key: 'postalCode',
        label: t('common.forms.labels.postal_code'),
        validators: [
          {
            validate: 'regex_matches',
            regex: `^.{0,9}$`,
            error: t('pages.suppliers.importer.validation.postal_code.error')
          }
        ]
      },
      {
        key: 'region',
        label: t('common.forms.labels.region')
      },
      {
        key: 'country',
        label: t('common.forms.labels.country'),
        type: 'select',
        options: countries.map(({ name, iso }) => {
          return { value: iso, label: name }
        })
      },
      {
        key: 'bank_name',
        label: t('pages.suppliers.bank_details')
      },
      {
        key: 'iban',
        label: t('common.forms.labels.iban')
      },
      {
        key: 'swift',
        label: t('common.forms.labels.swift')
      },
      {
        key: 'glnNumber',
        label: t('pages.suppliers.form.placeholder.gln_number')
      },
      {
        key: 'taxNumber',
        label: t('pages.suppliers.form.placeholder.tax_number')
      },
      {
        key: 'taxSubject',
        type: 'checkbox',
        label: t('pages.suppliers.form.placeholder.tax_subject')
      },
      {
        key: 'paymentTerms',
        label: t('pages.suppliers.form.placeholder.payment_terms')
      },
      {
        key: 'accountsReceivable',
        label: t('pages.suppliers.form.placeholder.account_receivable'),
        type: 'select',
        options: createAccountOptions(resources?.value?.accounts)
      },
      {
        key: 'accountsPayable',
        label: t('pages.suppliers.form.placeholder.account_payable'),
        type: 'select',
        options: createAccountOptions(resources?.value?.expenseAccounts)
      }
    ])

    //computed
    const shouldAutoGenerateSupplierNumber = computed(
      () =>
        !!get(
          clientAccountConfiguration.value,
          'suppliers.generate_supplier_number'
        )
    )

    const supplierNumberValidators = computed(() =>
      shouldAutoGenerateSupplierNumber.value ? [] : [{ validate: 'required' }]
    )

    const importer = computed(
      () =>
        new FlatfileImporter(FLATFILE_LICENSE_KEY, {
          fields: fields.value,
          type: t('pages.suppliers.title'),
          allowInvalidSubmit: false,
          managed: true,
          disableManualInput: true,
          devMode: process.env.NODE_ENV !== 'production',
          styleOverrides: {
            primaryButtonColor: '#279ff6',
            invertedButtonColor: '#7bbaf3'
          },
          i18nOverrides: {
            de: {
              otherLocales: ['de-DE'],
              overrides: i18nOverrides({
                header: tm('pages.suppliers.importer.flatfile.header'),
                header2: tm('pages.suppliers.importer.flatfile.header2')
              })
            },
            en: {
              otherLocales: ['en-US', 'en-GB'],
              overrides: i18nOverrides({
                header: tm('pages.suppliers.importer.flatfile.header'),
                header2: tm('pages.suppliers.importer.flatfile.header2')
              })
            }
          }
        })
    )

    return {
      userId,
      orgName,
      resources,
      importer,
      clientAccountConfiguration,
      suppliersCount,
      supplierStore,
      shouldAutoGenerateSupplierNumber
    }
  },
  async mounted() {
    await this.fetchResources()
    this.importer.setCustomer({ userId: this.userId, name: this.orgName })
    this.importer.registerRecordHook(this.validateRecords)
  },
  methods: {
    async fetchResources() {
      this.resources = await this.$resourceFetch('expenseAccounts', 'accounts')
    },
    async handleImport() {
      this.$ampli.eventWithBaseProps('supplierImportButtonClicked')
      try {
        const results = await this.importer.requestDataFromUser()
        this.importer.displayLoader()
        await this.createSuppliers(results.data)
        this.importer.displaySuccess(
          this.$t('pages.suppliers.importer.messages.success')
        )
        this.$emit('refresh')
      } catch (err) {
        // Flatfile throws undefined error when user closes the importer by clicking X button.
        if (err) {
          this.$logException(err, { trackError: false })
          this.importer.displayError(
            this.$t('pages.suppliers.importer.messages.error')
          )
        }
      }
    },
    validateRecords(record, index, mode) {
      let out = {}
      // for performance it's best to use registerRecordHook only for user changes
      if (mode !== 'change') return out

      if (record.iban && !isValidIban(record.iban)) {
        out.iban = {
          info: [
            {
              message: this.$t('common.forms.rules.field_warnings.iban_format'),
              level: 'error'
            }
          ]
        }
      }

      if (record.swift && !isValidSwift(record.swift)) {
        out.swift = {
          info: [
            {
              message: this.$t(
                'common.forms.rules.field_warnings.swift_format'
              ),
              level: 'error'
            }
          ]
        }
      }

      if (record.paymentTerms && !+record.paymentTerms) {
        out.paymentTerms = {
          info: [
            {
              message: this.$t(
                'pages.suppliers.importer.validation.payment_terms.error'
              ),
              level: 'error'
            }
          ]
        }
      }

      return out
    },
    async createSuppliers(suppliers) {
      const normalizedSuppliers = this.normalizeSuppliers(suppliers)
      await this.createSuppliersByChunks(normalizedSuppliers)
      if (this.suppliersCount === 0) {
        this.supplierStore.checkSuppliersCount()
      }
    },
    // Create suppliers by chunks and returns an array of the created suppliers
    async createSuppliersByChunks(suppliers) {
      let createdSuppliers = []
      const arrayOfSuppliers = splitArrayIntoChunks(suppliers, CHUNK_NUMBER)
      const thSuppliers = th.suppliers()
      for (const suppliers of arrayOfSuppliers) {
        try {
          const response = await thSuppliers.bulkCreate(suppliers)
          createdSuppliers = createdSuppliers.concat(
            response?.data?.updated_suppliers || []
          )
        } catch (err) {
          this.$logException(err, { trackError: false })
          this.importer.displayError(
            this.$t('pages.suppliers.importer.messages.error')
          )
        }
      }

      return createdSuppliers
    },
    cleanEmptyStringFields(supplier) {
      let cleanSupplier = { ...supplier }
      // replace empty strings with null
      Object.keys(supplier).forEach((key) => {
        if (isEmptyString(supplier[key])) {
          cleanSupplier[key] = null
        }
      })
      return cleanSupplier
    },
    normalizeSuppliers(suppliers) {
      return suppliers.map((supplier) => {
        const cleanSupplier = this.cleanEmptyStringFields(supplier)
        const {
          street = null,
          streetNumber = null,
          locality = null,
          country = null,
          postalCode = null,
          region = null,
          bank_name = null,
          iban = null,
          swift = null,
          paymentTerms,
          accountsPayable,
          accountsReceivable,
          phoneNumber = null,
          ...restSupplier
        } = cleanSupplier

        const normalizedSupplier = { ...restSupplier }

        if (paymentTerms) {
          normalizedSupplier.paymentTerms = +paymentTerms
        }

        if (phoneNumber) {
          normalizedSupplier.phoneNumbers = [
            { type: phoneTypes[0], number: phoneNumber }
          ]
        }

        normalizedSupplier.accountsReceivable = accountsReceivable
          ? [accountsReceivable]
          : []
        normalizedSupplier.accountsPayable = accountsPayable
          ? [accountsPayable]
          : []

        if (
          street ||
          streetNumber ||
          locality ||
          country ||
          postalCode ||
          region
        ) {
          normalizedSupplier.addresses = [
            {
              type: addressTypes[0],
              street,
              streetNumber,
              country,
              postalCode,
              region,
              locality,
              lines: null
            }
          ]
        }

        if (bank_name || iban || swift) {
          normalizedSupplier.bankAccounts = [
            {
              name: bank_name,
              iban,
              swift
            }
          ]
        }

        return normalizedSupplier
      })
    }
  }
}
</script>
