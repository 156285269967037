import { defineStore } from 'pinia'
import th from '@tillhub/javascript-sdk'

export const useSuppliersStore = defineStore('suppliers', {
  state: () => ({ suppliersCount: 1 }),
  getters: {
    suppliersExists: (state) => state.suppliersCount > 0
  },
  actions: {
    async checkSuppliersCount() {
      try {
        const { data } = await th
          .suppliers()
          .meta({ query: { deleted: false } })
        this.suppliersCount = data?.count || 0
      } catch (err) {
        this.$logException(err, { trackError: false })
        this.suppliersCount = 1
      }
    }
  }
})
