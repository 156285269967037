export const phoneTypes = ['main', 'home', 'work', 'mobile']

export const addressTypes = ['default', 'return']

export function genInitialPhone() {
  return { phone_type: null, phone_number: null }
}

export function genInitialBank() {
  return { name: null, iban: null, swift: null }
}

export function genInitialData() {
  return {
    accountsPayable: [],
    accountsReceivable: [],
    addresses: [],
    bankAccounts: [],
    companyName: null,
    description: null,
    email: null,
    firstName: null,
    glnNumber: null,
    lastName: null,
    number: null,
    paymentTerms: null,
    phoneNumbers: [],
    taxNumber: null,
    taxSubject: false
  }
}

export function createAccountOptions(list = []) {
  return list.map(({ id, fa_account_number, name }) => {
    return {
      value: id,
      label: `${fa_account_number || ''} - ${name}`.trim()
    }
  })
}
