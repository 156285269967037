export function genInitialBank() {
  return { name: null, iban: null, swift: null }
}

// Note that this implementation does not validate the country code or account number format of the IBAN
// it simply checks that the IBAN is the correct length and has a valid mod-97 checksum.
// If we need more advanced validation, you should consider using a third-party library or API.

/**
 * Validates IBAN code.
 * @param iban IBAN code
 */
export function isValidIban(iban) {
  // Remove all spaces and convert to uppercase
  iban = iban.replace(/\s+/g, '').toUpperCase()

  // Check that the IBAN is the correct length
  if (iban.length < 15 || iban.length > 34) {
    return false
  }

  // Move the first 4 characters to the end of the string
  iban = iban.substring(4) + iban.substring(0, 4)

  // Replace each letter with two digits (A = 10, B = 11, etc.)
  iban = iban
    .split('')
    .map(function (char) {
      var code = char.charCodeAt(0)
      if (code >= 65 && code <= 90) {
        return (code - 55).toString()
      } else {
        return char
      }
    })
    .join('')

  // Check that the mod-97 of the IBAN is 1
  var remainder = iban.slice(0, 2)
  for (var i = 2; i < iban.length; i += 7) {
    remainder = parseInt(remainder + iban.slice(i, i + 7), 10) % 97
  }
  return remainder === 1
}

/**
 * Validates SWIFT code.
 * Follow https://en.wikipedia.org/wiki/ISO_9362 for details
 * @param swift SWIFT code
 */
export function isValidSwift(swift) {
  const SWIFT_FORMAT_REGEX = /^([A-Z]){4}([A-Z]){2}([0-9A-Z]){2}([0-9A-Z]{3})?$/
  return SWIFT_FORMAT_REGEX.test(swift)
}
